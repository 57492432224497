import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

import { connect } from "react-redux"

import { getPropertiesAction, getTotalListFilters, REDUX_UPDATE_DRAWN_POLYGON, REDUX_UPDATE_FILTERS } from "../redux/propertiesDucks"
import { getDevelopmentsAction } from "../redux/developmentsDucks"

import { existOperation, existDevelopment } from "../helpers/helper.rendering"
import { getOperationView } from "../helpers/helper.filters"

import { getNoveltiesAction } from "../redux/noveltiesDucks"
import { getActionsAction } from '../redux/actionsDucks'; 

import { deleteSharp } from "../helpers/helper.map"

const MenuNav = ({
  filters,
  curFilters,
  api_key,
  developments,
  novelties,
  dispatch,
  drawnPolygon,
  setOpen,
  isFooter,
}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        global_email
        social {
          facebook
          instagram
          linkedin
          youtube
          twitter
        }
        branch_office {
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          footer {
            title_contact
            title_social
            title_newsletter
            title_office
            button_contact {
              link
              value
            }
          }
        }
      }
    }
  `)

  const location = useLocation()
  const pathname = location.pathname.split("/")[1]
//   const iSFooter = isFooter

  useEffect(() => {
    if (api_key) {
      dispatch(getDevelopmentsAction())
      dispatch(getTotalListFilters())
    }
  }, [api_key])

  useEffect(() => {
    dispatch(getNoveltiesAction())
    dispatch(getActionsAction())
},[])

  const staticBody = () => {
    var body = document.body
    body.classList.remove("overflow-hidden")
  }

  const isResultView = () => {
    if (pathname.includes("venta") || pathname.includes("alquiler")) {
      return true
    }
    return false
  }

  const checkActive = (_pathname) => {
    if(pathname === 'propiedad' && location.pathname.includes(_pathname)){return true}
    if((_pathname === 'venta' || _pathname === "alquiler") && location.pathname.includes('venta') || location.pathname.includes('alquiler')){
        if(curFilters.operation.includes(_pathname)){
            return true;
        }
    }
    else{
        if(pathname === (_pathname)){
            return true;
        }
    }
    return false
}


  return realEstate.sections ? (
    <ul className={"nav-menu " + (setOpen ? "open" : "close")}>
      <li className={pathname === "" && !isResultView() && !location.pathname.includes("propiedad") ? "active " : ""}>
        <Link onClick={() => staticBody()} className="" to="/">
          home
        </Link>
      </li>
      {existDevelopment(developments) ? (
        <li className={pathname === "emprendimientos" ? "active " : ""}>
          <Link
            onClick={() => staticBody()}
            className=""
            to="/emprendimientos/"
          >
            emprendimientos
          </Link>
        </li>
      ) : (
        ""
      )}
      {existOperation("venta", filters) ? (
        <li
          className={
            (checkActive('venta') ? 'active' : '')
          }
        >
          <Link onClick={() => staticBody() +
            + deleteSharp(drawnPolygon,dispatch,REDUX_UPDATE_DRAWN_POLYGON)
            + dispatch(REDUX_UPDATE_COORDINATES([])) // Elimino filtro por area global
            + dispatch(REDUX_UPDATE_FILTERS(curFilters,"venta")) + dispatch(getPropertiesAction())
            + (dispatch(REDUX_UPDATE_FILTERS(
            {
              location: [],
              type: [],
              operation: "venta",
              environments: "",
              bedrooms: "",
              price:{
                type:'',
                priceMin:'',
                priceMax:''
              },
              surface:{
                type:'',
                surfaceMin:"",
                surfaceMax:"",
              },
              environments_types: [],
              generals: [],
              services: [],
              specials: [],
            }
            )))} className="" to="/venta/">
            venta
          </Link>
        </li>
      ) : (
        ""
      )}
      {existOperation("alquiler", filters) ? (
        <li
            className={(checkActive('alquiler') ? 'active' : '')
          }
        >
          <Link onClick={() => staticBody() + 
          + deleteSharp(drawnPolygon,dispatch,REDUX_UPDATE_DRAWN_POLYGON)
          + dispatch(REDUX_UPDATE_COORDINATES([])) 
          + dispatch(REDUX_UPDATE_FILTERS(curFilters,'alquiler')) + dispatch(getPropertiesAction())+
            + (dispatch(REDUX_UPDATE_FILTERS(
            {
              location: [],
              type: [],
              operation: "alquiler",
              environments: "",
              bedrooms: "",
              price:{
                type:'',
                priceMin:'',
                priceMax:''
              },
              surface:{
                type:'',
                surfaceMin:"",
                surfaceMax:"",
              },
              environments_types: [],
              generals: [],
              services: [],
              specials: [],
            }
            )))} className="" to="/alquiler/">
            alquiler
          </Link>
        </li>
      ) : (
        ""
      )}
      <li className={pathname === "tasaciones" ? "active " : ""}>
        <Link onClick={() => staticBody()} className="" to="/tasaciones/">
          tasaciones
        </Link>
      </li>
      {/* <li className={(pathname === 'servicios' ? 'active ' : '')}><Link  onClick={() => staticBody()} className="" to="/servicios/">servicios</Link></li> */}
      <li className={pathname === "nosotros" ? "active " : ""}>
        <Link onClick={() => staticBody()} className="" to="/nosotros/">
          nosotros
        </Link>
      </li>
      <li className={(pathname === 'novedades' ? 'active ' : '') + (novelties.length === 0 && ' d-none ')}>
        <Link  onClick={() => staticBody()} className="" to="/blog/">novedades</Link>
      </li>
      <li className={pathname === "contacto" ? "active " : ""}>
        <Link onClick={() => staticBody()} className="" to="/contacto/">contacto</Link>
      </li>
      {/* {iSFooter ? (
        <li className={pathname === "questions" ? "active " : ""}>
          <Link onClick={() => staticBody()} className="mb-0" to="/questions/">
            Preguntas frecuentes
          </Link>
        </li>
      ) : (
        ""
      )} */}
    </ul>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    filters: state.properties.totalListFilters,
    curFilters: state.properties.filters,
    novelties: state.novelties.novelties,
    api_key: state.settings.keys.tokko,
    drawnPolygon: state.properties.drawnPolygon,
    developments: state.developments.developments,
  }),
  null
)(MenuNav)