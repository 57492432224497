export const getOperations = (property) => {
    if(property.operations){
        const operationsArray = [];
        property.operations.map((operation) => {
            operationsArray.push(operation.operation_type)
        })
        return operationsArray;
    }
    return ['']
}

export const getType = (property) => {
    if(property.type){
        return property.type.name
    }
    return ''
} 

export const getEnvironment = (property) => {
    return property.room_amount;
}

export const getSurface = (property) => {
    let surface = 0
    if(property.type){
        if(property.type.name.toLowerCase() === 'terreno'){
            surface = property.surface
        }
        else{
            surface = property.total_surface
        }
        if(surface > 0) return surface.split(".")[0] + " " + property.surface_measurement
    }
    return null
}

export const getRooms = (propert) => {
    return propert.suite_amount;
}

export const getSurfaces = (property) => {
    const surfacesArray = {
        roofed : property.roofed_surface.split(".")[0],
        unroofed : property.unroofed_surface.split(".")[0],
        semiroofed_surface : property.semiroofed_surface.split(".")[0],
        surface : property.surface.split(".")[0],
        total_surface : property.total_surface.split(".")[0],
        measurement : property.surface_measurement
    }
    return surfacesArray;
} 

export const getLocation = (property) => {
    if(property.location){
        return property.location.name
    }
    return ''
}

export const getFakeAddres = (property) => {
    return property.fake_address
}

export const getRealAddress = (property) => {
    return property.real_address
}

export const getPrices = (property,operation = '') => {
    // if(!property?.web_price){return ("Precio a consultar")}
    if(property?.operations){

        const pricesArray = []
        
        property?.operations.map((operation) => {
            const obj = {}
            obj.type = operation.operation_type
            obj.price = !property?.web_price ? "Precio a consultar" : operation.prices[0].price
            obj.currency = !property?.web_price ? " " : operation.prices[0].currency
            pricesArray.push(obj)
        })
        if(operation !== ''){
            if(operation === 'temporario'){
                return pricesArray.filter(element => element.type.toLowerCase().replaceAll(" ","-") === 'alquiler-temporario' )
            }
            return pricesArray.filter(element => element.type.toLowerCase().replaceAll(" ","-") === operation.toLowerCase().replaceAll(" ","-") )
        }
        return pricesArray;
    }
    return []
} 

export const getTags = (property) => {
    if(property.tags){
        return property.tags
    }
    return []
}
export const getCustomTags = (property) => {
    if(property.custom_tags){
        return property.custom_tags
    }
    return []
}

export const hasGarage = (property) => {
    return property.parking_lot_amount ? true : false
}

export const makeLink = (property) => {
    return "/propiedad/" + getOperations(property)[0].toLowerCase() + '/' + property.id
}

export const makeTextBathrooms = (property) => {
    let text = ""
    // Baños
    if(property.bathroom_amount === 0){return ""}
    text += " " + property.bathroom_amount ? property.bathroom_amount + "/" + " baño" : ''
    text += property.bathroom_amount > 1 ? 's / ' : ' / '
    
  
    return text
}

export const makeTextToilet = (property) => {
    let text = ""
   
    // Toilettes
    if(property.toilet_amount){
        text += " " + property.toilet_amount ? property.toilet_amount + "/" + " toilette" : ''
        text += property.toilet_amount > 1 ? 's / ' : ' / '
    }
    return text
}

export const makeTextRooms = (property) => {
    let text = ""
    // Ambientes
    if(property.room_amount) {
        text += property.room_amount ? property.room_amount + "/" + " ambiente" : ''
        text += property.room_amount > 1 ? 's / ' : ' / '
    }
    return text
}
export const makeTextAmenities = (property) => {
    let text = ""
    // Dormitorios
    if(property.suite_amount){
        text += " " + property.suite_amount ? property.suite_amount + "/" + " dormitorio" : ''
        text += property.suite_amount > 1 ? 's / ' : ' / '
    }
    return text
}
export const makeTextParkings = (property) => {
    let text = ""
    // Cochera
    if(hasGarage(property)){
        // text += hasGarage(property) ? " cochera" : ''
        text += property.parking_lot_amount ? property.parking_lot_amount + "/" + " cochera" : ''
        text += property.parking_lot_amount > 1 ? 's / ' : ' / '
    }
    return text
}

export const amenitiesTags = (property) => {
    let amenities = {
        room: property.room_amount > 0 ? `${property.room_amount}` : `00`,
        bathroom: property.bathroom_amount > 0 ? `${property.bathroom_amount}` : `00`,
        suite: property.suite_amount > 0 ? `${property.suite_amount}` : `00`,
        toilet: property.toilet_amount > 0 ? `${property.toilet_amount}` : `00`,
        // garage: hasGarage(property) > 0 ? `1` : `00`
        garage: property.parking_lot_amount > 0 ? `${property.parking_lot_amount}` : `00`
      }
    return amenities
}

export const makeTextSurfaces = (property) => {
    let text = ""
    // Total
    if(property?.type?.name?.toLowerCase() === 'terreno'){
        text += " " + property.surface > 0 ? property.surface.split(".")[0] + " m2 / terreno / " : ''
    }
    text += " " + property.total_surface > 0 ? property.total_surface.split(".")[0] + " m2 / totales / " : ''
    // Roofed
    // text += property.roofed_surface > 0 ? property.roofed_surface.split(".")[0] + " m2 cubiertos / " : ''
    // Unroofed
    // text += " " + property.unroofed_surface > 0 ? property.unroofed_surface.split(".")[0] + " m2 descubiertos / " : ''
    // Terreno
    // text += " " + property.surface > 0 ? property.surface.split(".")[0] + " m2 terreno /" : ''

    return text
}

export const makeTextSurfacesCubierta = (property) => {
    let text = ""
    // Roofed
    text += property.roofed_surface > 0 ? property.roofed_surface.split(".")[0] + " m2 / cubiertos / " : ''

    return text
}

export const makeTextTags = (property) => {
    let text = ""
    if(property.tags){
        let index = 0
        while(index < 6 && index < property.tags.length){
            text += property.tags[index].name + " / "
            index ++;
        }
    }
    return text
}

export const getBluePrints = (property) => {
    if(property.photos){
        return property.photos.filter(photo => photo.is_blueprint === true)
    }
    return []
}

export const getTour = (property,all = false) => {
    if(property.videos){
        
        return property.videos.filter(video => video.provider === 'matterport' || video.provider === 'hauzd' || video.provider === 'kuula Share Collection' || video.provider === '360magictour' || video.provider === 'vista360' || video.provider === 'roundme')
    }
    return []
}

export const getVideos = (property) => {
    if(property.videos){
        return property.videos.filter(video => video.provider === 'youtube' || video.provider === 'vimeo')
        // return list_videos[list_videos.length - 1]
    }
    return []
}

export const getVideo = (property) => {
    if(property.videos){
        const list_videos = property.videos.filter(video => video.provider === 'youtube' || video.provider === 'vimeo')
        return list_videos
    }
    return []
}



export const getPublicationTitle = (property) => {
    if(property?.publication_title){
        return property.publication_title
    }
    return ''
}

export const getExpenses = (property) => {
    if(property.expenses){
        return property.expenses
    }
    return null
}

export const getPropertiesByOperation = (properties,operation) => {  
    return properties.filter( property => property.operations.find(op => op.operation_type.toLowerCase().replace(" ", "-") === operation))
}