export const makeCustom = (custom) => {
    const { name, id } = custom;
    return {
        name,
        id,
    };
}

export const getServices = (filters) => {
    return filters.tags.filter(element => element.tag_type === 1)
}

export const getEnvironment = (filters) => {
    return filters.tags.filter(element => element.tag_type === 2)
}

export const getGenerals = (filters) => {
    return filters.tags.filter(element => element.tag_type === 3)
}

export const getCustoms = (filters) => {
    if(filters){
        return filters?.data?.objects
    }
    return []
}

export const getView = (route) => {
    return route.pathname.split('/')[1]

}

export const getOperationView = (filters) => {
    if(filters !== undefined){
        return filters.operation
    }
}

export const checkOperation = (filters,operation) => {
    if(filters !== undefined){
        return (filters.operation_types.find(element => element.operation_type === operation))
    }
}

export const checkEnvironment = (filters,environment) => {
    if(filters !== undefined){
        if(environment === 9){
            return (filters.room_amount.find(element => element.amount >= environment))
        }
        return (filters.room_amount.find(element => element.amount === environment))
    }
}
export const checkBedroom = (filters,bedroom) => {
    if(filters !== undefined){
        if(bedroom === 9){
            return (filters.suite_amount.find(element => element.amount >= bedroom))
        }
        return (filters.suite_amount.find(element => element.amount === bedroom))
    }
}

export const getCodeOperation = (operation) => {
    switch(operation.toLowerCase()){
        case 'venta':{
            return 1
        }
        case 'alquiler':{
            return 2
        }
        case 'alquiler-temporario':{
            return 3
        }
        default:{
            return 0
        }
    }
}

export const checkView = (route,location) => {
    const path = route.pathname.split('/')
    if(path.find(element => element.toLowerCase() === location.toLowerCase()) === undefined){
        return false
    }
    return true
}

export const getType = (filters) => {
    return filters.type
}

export const getTypes = (totalFilters) => {
    return totalFilters.property_types
}

export const getLocations = (totalFilters) => {
    return totalFilters.locations
}

export const textTypes = (types) => {
    let text = ''
    types.map((type,index) => {
        text += type.name
        if(type.name[type.name.length-1] === 'n' || type.name[type.name.length-1] === 'l' || type.name[type.name.length-1] === 's'){
            text += 'es'
        }
        else{
            text += 's'
        }
        if(type.name[type.name.length-1] === 'h'){
            text += "s"
        }
        if(index < types.length - 1) text += ', '
    })
    return text
}

//Función para checkear los estados de cada filtro
export const checkCurrentFilters = (filters,filter,value) => {
    if(filters?.operation !== ''){
        switch(filter){
            case 'location' :{
                if(filters?.location){
                    if (filters.location.find(element => element.name.toString().toLowerCase() == value.toString().toLowerCase())){
                        return true;
                    }
                }
                return false
            }
            case 'type' :{
                if (filters?.type?.find(element => element.name.toString().toLowerCase() == value.toString().toLowerCase())){
                    return true;
                }
                return false
            }
            case 'operation' : {
                if(filters.operation){
                    if(filters.operation.toLowerCase() === value.toLowerCase()){
                        return true
                    }
                }
                return false
            }
            case 'price-type' : {
                if (filters?.price?.type.toLowerCase().includes(value.toLowerCase())){
                    return true
                }
                return false
            }
            case 'surface-type' : {
                if (filters?.surface?.type.toLowerCase() === (value.toLowerCase())){
                    return true
                }
                return false;
            }
            case 'environments' : {
                // if (filters?.environments.toLowerCase() == (value.toLowerCase())){
                //     return true
                // }
                return false
            }
            case 'age' : {
                if(filters.age){
                    if(filters.age.toLowerCase() === value.toLowerCase()){
                        return true
                    }
                }
                return false
            }
            case 'environments_types' : {
                if (filters?.environments_types?.find(element => element.name.toString().toLowerCase() === value.name.toString().toLowerCase())){
                    return true;
                }
                return false
            }
            case 'generals' : {
                if (filters?.generals?.find(element => element.name.toString().toLowerCase() == value.name.toString().toLowerCase())){
                    return true;
                }
                return false
            }
            case 'services' : {
                if (filters?.services?.find(element => element.name.toString().toLowerCase() == value.name.toString().toLowerCase())){
                    return true
                }
                return false
            }
            case 'specials' : {
                // if (filters.specials.find(element => element.name.toString().toLowerCase() == value.name.toString().toLowerCase())){
                if (filters.specials.find(element => element?.id.toString() === value?.id.toString())){
                    return true
                }
                return false
            }
        }
    }
    return false
}

//Función para actualizar el listado de filtros
export const updateFilters = (setFilters,filters,filter,value,params = {}) => {
    // Params = mediciones
    const {arsMax,usdMax,totMax,cubMax} = params;
    switch(filter){
        //
        case 'allLocations' :{ //Cambio todas juntas (normalmente actualizando barrios)
            setFilters((filters) => ({
                ...filters,
                location: value
            }))
            break;
        }

        case 'location' :{
            if (filters.location.find(element => element.id == value.id)){
                const newLocations = filters.location.filter(element => element.id !== value.id);
                setFilters((filters) => ({
                    ...filters,
                    location:newLocations
                }))
                break
            }
            else{
                setFilters((filters) => ({
                    ...filters,
                    location: [...filters.location, value]
                }))
                break;
            }
        }
        case 'type' :{
            if (filters.type.find(element => element.id == value.id)){
                const newTypes = filters.type.filter(element => element.id !== value.id);
                setFilters((filters) => ({
                    ...filters,
                    type:newTypes
                }))
                break
            }
            else{
                setFilters((filters) => ({
                    ...filters,
                    type: [...filters.type, value]
                }))
                break;
            }
        }
        case 'operation' : {
            setFilters((filters) => ({
                ...filters,
                operation:value
            }))
            break;
        }
        case 'price-type' : {
            if(value.toLowerCase() === 'dolares' || value.toLowerCase() === 'pesos' || value.toLowerCase() === 'null'){
                if(value == "null"){
                    setFilters((filters) => ({
                        ...filters,
                        price:{...filters.price,
                            type:''
                        }
                    }))
                    break;
                }
                setFilters((filters) => ({
                    ...filters,
                    price:{...filters.price,
                        type:value
                    }
                }))
                break;
            }
        }
        case 'price-min' : {
            setFilters((filters) => ({
                ...filters,
                price:{...filters.price,
                    priceMin:value
                }
            }))
            break;
        }
        case 'price-max' : {
            if(value == 200000 && filters.price.priceMax == value){
                setFilters((filters) => ({
                    ...filters,
                    price:{...filters.price,
                        priceMax:5000000
                    }
                }))
                break;
            }
            setFilters((filters) => ({
                ...filters,
                price:{...filters.price,
                    priceMax:value
                }
            }))
            break;
        }
        case 'price' : {
            var priceDefaultMax = 0;
            if(filters.price.type.toLowerCase() == 'dolares'){
                priceDefaultMax = usdMax;
            }
            else{
                priceDefaultMax = arsMax;
            }
            setFilters((filters) => ({
                ...filters,
                price:{...filters.price,
                    priceMax:priceDefaultMax,
                    priceMin:0
                }
            }))
            break;
        }
        case 'surface-type' : {
            if(value.toLowerCase() == 'total' || value.toLowerCase() == 'cubierta'){
                // if(value.toLowerCase() == filters.surface.type.toLowerCase()){
                //     setFilters((filters) => ({
                //         ...filters,
                //         surface:{...filters.surface,
                //             type:''
                //         }
                //     }))
                //     break;
                // }
                setFilters((filters) => ({
                    ...filters,
                    surface:{...filters.surface,
                        type:value
                    }
                }))
                break;
            }
            setFilters((filters) => ({
                ...filters,
                surface:{...filters.surface,
                    type:value
                }
            }))
            break;
        }
        case 'surface-min' : {
            setFilters((filters) => ({
                ...filters,
                surface:{...filters.surface,
                    surfaceMin:value
                }
            }))
            break;
        }
        case 'surface-max' : {
            setFilters((filters) => ({
                ...filters,
                surface:{...filters.surface,
                    surfaceMax:value
                }
            }))
            break;
        }
        case 'surface' : {
            var surfaceDefaultMax = 0;
            if(filters.surface.type.toLowerCase() == 'total'){
                surfaceDefaultMax = totMax;
            }
            else{
                surfaceDefaultMax = cubMax;
            }
            setFilters((filters) => ({
                ...filters,
                surface:{...filters.surface,
                    surfaceMax:'',
                    surfaceMin:''
                }
            }))
            break;
        }
        case 'environments' : {
            setFilters((filters) => ({
                ...filters,
                environments:value
            }))
            break
        }
        case 'bedrooms' : {
            setFilters((filters) => ({
                ...filters,
                bedrooms:value
            }))
            break
        }
        case 'age' : {
            setFilters((filters) => ({
                ...filters,
                age:value,
            }))
            break;
        }
        case 'environments_types' : {
            if (filters.environments_types.find(element => element.id == value.id)){
                const newEnvironments = filters.environments_types.filter(element => element.id !== value.id);
                setFilters((filters) => ({
                    ...filters,
                    environments_types:newEnvironments
                }))
                break
            }
            else{
                setFilters((filters) => ({
                    ...filters,
                    environments_types: [...filters.environments_types, value]
                }))
                break;
            }

        }
        case 'generals' : {
            if (filters.generals.find(element => element.id == value.id)){
                const newGenerals = filters.generals.filter(element => element.id !== value.id);
                setFilters((filters) => ({
                    ...filters,
                    generals:newGenerals
                }))
                break
            }
            else{
                setFilters((filters) => ({
                    ...filters,
                    generals: [...filters.generals, value]
                }))
                break;
            }
        }
        case 'services' : {
            if (filters.services.find(element => element.id == value.id)){
                const newServices = filters.services.filter(element => element.id !== value.id);
                setFilters((filters) => ({
                    ...filters,
                    services:newServices
                }))
                break
            }
            else{
                setFilters((filters) => ({
                    ...filters,
                    services: [...filters.services, value]
                }))
                break;
            }
        }
        case 'specials' : {
            if (filters.specials.find(element => element.id.toString() == value.id.toString())){
                const newSpecials = filters.specials.filter(element => element.id.toString() !== value.id.toString());
                setFilters((filters) => ({
                    ...filters,
                    specials:newSpecials
                }))
                break
            }
            else{
                setFilters((filters) => ({
                    ...filters,
                    specials: [...filters.specials, value]
                }))
                break;
            }
        }
    }
}

//Función para actualizar la ruta según los filtros
export const updateRouter = (filters) => {
    if(filters.operation){
        // los guiones son los espacios en las palabras, cada variables se separa con una coma ","
        var router = "/" +  filters.operation.toLowerCase().replace(/ /g,"-");
        if(filters.type != ''){
                router +=  '/';
                for (let index = 0; index < filters?.type?.length; index++) {
                    if(!router.includes(filters.type[index].name.toLowerCase().replace(/ /g,"-"))){
                        router +=  filters.type[index].name.toString().toLowerCase().replace(" ","-");
                        if(index < filters?.type?.length - 1){ router += ',' }
                    }
                }
                if(router[router.length-1] == ','){
                    router = router.substring(0,router.length - 1);
                }
        }
        if(filters.location != ''){
                router +=  '/';
                for (let index = 0; index < filters?.location?.length; index++) {
                    if(!router.includes(filters.location[index].name.toLowerCase().replace(/ /g,"-"))){
                        router +=  filters.location[index].name.toLowerCase().replace(/ /g,"-");
                        if(index < filters.location.length - 1){ router += ',' }
                    }
                }
        }
        if(filters?.price?.type || filters?.price?.minPrice || filters?.price?.maxPrice){
                router +=  '/';
                if(filters.price.type){router+= '' + filters.price.type.toLowerCase() + '-'}
                if(filters.price.type){router+= 'desde-' + filters.price.priceMin + '-'}
                if(filters.price.type){router+= 'hasta-' + filters.price.priceMax + '-'}
                // Borro si hay algún guón de más
                if(router[router.length-1] == '-'){
                        router = router.substring(0,router.length - 1);
                }
        }
        if(filters?.surface?.type || filters?.surface?.surfaceMin || filters?.surface?.surfaceMax){
                router +=  '/';
                if(filters.surface.type){router+= '' + filters.surface.type.toLowerCase() + '-'}
                if(filters.surface.type){router+= 'desde-' + filters.surface.surfaceMin + '-'}
                if(filters.surface.type){router+= 'hasta-' + filters.surface.surfaceMax + '-'}
                // Borro si hay algún guón de más
                if(router[router.length-1] == '-'){
                        router = router.substring(0,router.length - 1);
                }
        }
        if(filters.age != '' && filters.age !== undefined){
            router +=  '/';
            router += filters?.age?.toLowerCase().replace(/ /g,"-").replace("años",'').replace("+",'mas') + (filters?.age?.includes("años") ? 'de-antiguedad' : '')
            if(router[router.length-1] == '-'){
                router = router.substring(0,router.length - 1);
            }
        }
        if(filters.environments !== ''){
            router +=  '/';
            router += "desde-" + filters?.environments?.toLowerCase().replace(/ /g,"").replace("-","-hasta-") + "-ambientes"
            if(router[router.length-1] == '-'){
                router = router.substring(0,router.length - 1);
            }
        }
        if(filters.bedrooms !== ''){
            router +=  '/';
            router += "desde-" + filters?.bedrooms?.toLowerCase().replace(/ /g,"").replace("-","-hasta-") + "-dormitorios"
            if(router[router.length-1] == '-'){
                router = router.substring(0,router.length - 1);
            }
        }
        if(filters.environments_types != ''){
                router +=  '/';
                for (let index = 0; index < filters?.environments_types?.length; index++) {
                    if(!router.includes(filters.environments_types[index].name.toLowerCase().replace(/ /g,"-"))){
                        router +=  filters.environments_types[index].name.toLowerCase().replace(/ /g,"-");
                        if(index < filters.environments_types.length - 1){ router += ',' }
                    }
                }
                if(router[router.length-1] == '-'){
                    router = router.substring(0,router.length - 1);
                }
        }
        if(filters?.generals != '' || filters?.services != '' || filters?.specials != ''){
                router +=  '/';
                //Agrego generales,services,specials separados por comas
                for (let index = 0; index < filters?.generals?.length; index++) {
                    if(!router.includes(filters.generals[index].name.toLowerCase().replace(/ /g,"-"))){
                        router +=  filters.generals[index].name.toLowerCase().replace(/ /g,"-");
                        router += ','
                    }
                }
                for (let index = 0; index < filters?.services?.length; index++) {
                    if(!router.includes(filters.services[index].name.toLowerCase().replace(/ /g,"-"))){
                        router +=  filters.services[index].name.toLowerCase().replace(/ /g,"-");
                        router += ','
                    }
                }
                for (let index = 0; index < filters?.specials?.length; index++) {
                    if(!router.includes(filters.specials[index].name.toLowerCase().replace(/ /g,"-"))){
                        router +=  filters.specials[index].name.toLowerCase().replace(/ /g,"-");
                        router += ','
                    }
                }
                if(router[router.length-1] == '-' || router[router.length-1] == ','){
                    router = router.substring(0,router.length - 1);
                }
        }
        // Borro si hay alguna coma de más
        if(router[router.length-1] == ',' || router[router.length-1] == '/'){
            router = router.substring(0,router.length - 1);
        }

        router = router.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        history.pushState(null,'',router)
    }
    
}

//Funcion para leer la ruta y cargar los filtros de la URL
export const loadFiltersRoute = (location,setFilters,filters,data,specials,setFiltersLoading,allLocations=[]) => {
    for (const values of location) { // Obtengo las rutas separadas por " / "
        if(values != ''){
            var iteratorGroup = values.split(",")   // Separo esos valores en " , "
            for (const iteratorSingle  of iteratorGroup) {
                var iteratorValue = iteratorSingle.replace(/-/g," "); // Parcheo los espacios que le puse para pasarlos por url
                if(iteratorValue.toLowerCase() === ("venta") || iteratorValue.toLowerCase() === ("alquiler") || iteratorValue.toLowerCase() === ("alquiler-temporario")){ // Consulto si alguno de los valores de la ruta coincide con un tipo de operación
                    setFilters({
                        ...filters,
                        operation:iteratorValue
                    })
                }
                if(iteratorValue.toLowerCase().includes("pesos") || iteratorValue.toLowerCase().includes("dolares")){ // Consulto si alguno de los valores de la ruta coincide con un tipo de operación
                    const splitIterator = (iteratorValue.split(" "))
                    setFilters((filters) => ({
                        ...filters,
                        price:{...filters.price,
                            type:splitIterator[0]
                        }
                    }))
                    if(splitIterator[2] > 0){
                        setFilters((filters) => ({
                            ...filters,
                            price:{...filters.price,
                                priceMin:splitIterator[2]
                            }
                        }))
                    }
                    if(splitIterator[4] > 0){
                        setFilters((filters) => ({
                            ...filters,
                            price:{...filters.price,
                                priceMax:splitIterator[4]
                            }
                        }))
                    }
                }
                if(iteratorValue.toLowerCase().includes("total") || iteratorValue.toLowerCase().includes("cubierta")){ // Consulto si alguno de los valores de la ruta coincide con un tipo de operación
                    const splitIterator = (iteratorValue.split(" "))
                    setFilters((filters) => ({
                        ...filters,
                        surface:{...filters.surface,
                            type:splitIterator[0]
                        }
                    }))
                    if(splitIterator[2] > 0){
                        setFilters((filters) => ({
                            ...filters,
                            surface:{...filters.surface,
                                surfaceMin:splitIterator[2]
                            }
                        }))
                    }
                    if(splitIterator[4] > 0){
                        setFilters((filters) => ({
                            ...filters,
                            surface:{...filters.surface,
                                surfaceMax:splitIterator[4]
                            }
                        }))
                    }
                }
                if(iteratorValue.toLowerCase().includes("estrenar") || iteratorValue.toLowerCase().includes("construccion")){
                    setFilters((filters) => ({
                        ...filters,
                        age: iteratorValue.toLowerCase().replace(/-/g," ")
                    }))
                }
                if(iteratorValue.toLowerCase().includes("antiguedad")){
                    setFilters((filters) => ({
                        ...filters,
                        age: iteratorValue.toLowerCase().replace(/-/g," ").replace("de antiguedad","años").replace("mas","+")
                    }))  
                }
                if(iteratorValue.toLowerCase().includes("ambientes")){
                    setFilters((filters) => ({
                        ...filters,
                        environments: iteratorValue.toLowerCase().split(" ")[1] + "-" + iteratorValue.toLowerCase().split(" ")[3]
                    }))  
                }
                if(iteratorValue.toLowerCase().includes("dormitorios")){
                    setFilters((filters) => ({
                        ...filters,
                        bedrooms: iteratorValue.toLowerCase().split(" ")[1] + "-" + iteratorValue.toLowerCase().split(" ")[3]
                    }))  
                }
                for (const types of data.objects.property_types) { // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (types)
                    if(iteratorValue.toLowerCase() === (types.type.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && !filters.type.find(element => element.id === types.id)){ // Los en minusculas que coinciden los agrego
                        setFilters((filters) => ({
                            ...filters,
                            type:[...filters.type,{id:types.id,name:types.type}]
                        }))
                    }
                }
                // for (const locations of data.objects.locations) { // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (locations)
                for (const locations of getAllLocations(allLocations)) { // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (locations)
                    if(iteratorValue.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "") === (locations.location_name.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){ // Los que en minusculas que coinciden los agrego
                        if(filters.location.find(element => element.name.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "") === iteratorValue.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                        }
                        else{
                            setFilters((filters) => ({
                                ...filters,
                                location:[...filters.location,{id:locations.location_id,name:locations.location_name}]
                            }))
                        }
                    }
                }
                for (const tags of data.objects.tags) { // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (locations)
                    if(iteratorValue.toLowerCase() === tags.tag_name.toLowerCase()){ // Los que en minusculas que coinciden los agrego
                        switch(tags.tag_type){
                            case 1:{
                                setFilters((filters) => ({
                                        ...filters,
                                        services:[...filters.services,{id:tags.tag_id,name:tags.tag_name}]
                                }))
                                break;
                            }
                            case 2:{
                                setFilters((filters) => ({
                                    ...filters,
                                    environments_types:[...filters.environments_types,{id:tags.tag_id,name:tags.tag_name}]
                                }))
                                break;
                            }
                            case 3:{
                                setFilters((filters) => ({
                                        ...filters,
                                        generals:[...filters.generals,{id:tags.tag_id,name:tags.tag_name}]
                                }))
                                break;
                            }
                        }
                    }
                }
                // for (const special of specials) { // Comparo los valores de la ruta con los valores existentes en los filtros de esta API_KEY en TOKKO (specials)
                //     if(iteratorValue.toLowerCase() === special.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") && !filters.specials.find(element => element.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === special.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){ // Los que en minusculas que coinciden los agrego
                //         setFilters((filters) => ({
                //                 ...filters,
                //                 specials:[...filters.specials,{id:special.id,name:special.name}]
                //         }))
                //     }
                // }
                if(iteratorValue.toString().toLowerCase() === '1 ambiente' || iteratorValue.toString().toLowerCase() === '2 ambientes' || iteratorValue.toString().toLowerCase() === '3 ambientes' || iteratorValue.toString().toLowerCase() === '4 ambientes' || iteratorValue.toString().toLowerCase() === '5 ambientes' || iteratorValue.toString().toLowerCase() === '6 ambientes' || iteratorValue.toString().toLowerCase() === '7 ambientes' || iteratorValue.toString().toLowerCase() === '8 ambientes' || iteratorValue.toString().toLowerCase() === 'mas de 9 ambientes'){ // Los que en minusculas que coinciden los agrego
                    setFilters((filters) => ({
                        ...filters,
                        environments:[iteratorValue]
                    }))
                    break;
                }
            }
        }else{
        }
    }
    setFiltersLoading(false)
}

//Función para localizar y ver cantidad de localidades segun filtros activos
export const getLocationCount = (filters,location) => {
    return filters.locations.find(element => element.location_id === location.location_id) ? filters.locations.find(element => element.location_id === location.location_id).count : 0
}

//Función para localizar y ver cantidad de tipos de propiedades segun filtros activos
export const getTypeCount = (filters,type) => {
    return filters.property_types.find(element => element.id === type.id) ? filters.property_types.find(element => element.id === type.id).count : 0
}

//Función para localizar y ver cantidad de tags segun filtros activos
export const getTagCount = (filters,tag) => {
    return filters.tags.find(element => element.tag_id === tag.tag_id) ?  filters.tags.find(element => element.tag_id === tag.tag_id).count : 0
}

//Función para localizar y ver cantidad de custom-tags segun filtros activos
export const getCustomTagCount = (filters,tag) => {
    if(filters){
        return filters.find(element => element.tag_id === tag.tag_id) ? filters.tags.find(element => element.tag_id === tag.tag_id).count : 0
    }
}

export const getGrandfathers = (allLocation) => {
    if(allLocation?.locations){
        return Object?.entries(allLocation?.locations?.Argentina).filter(element => element[0].toLowerCase() !== 'count' && element[0].toLowerCase() !== 'id').sort(function(a, b){return a[0].toString().localeCompare(b[0].toString())})
    }
    return []
}

export const getFathers = (grandFather) => {
    const names = Object?.entries(grandFather[1]).filter(element => element[0].toLowerCase() !== 'count' && element[0].toLowerCase() !== 'id')
    return names?.sort(function(a, b){return a[0].toString().localeCompare(b[0].toString())});
}

export const getChildrens = (father) => {
    return Object?.entries(father[1]).filter(element => element[0].toLowerCase() !== 'count' && element[0].toLowerCase() !== 'id').sort(function(a, b){return a[0].toString().localeCompare(b[0].toString())})
}

export const normalizeName = (location) => {
    return location.replaceAll(" ","-").replaceAll(".","").replaceAll(".","").replaceAll(")","").replaceAll("(","");
}

export const getAllLocations = (allLocations) =>{
    const locations = []
    // return allLocations.objects;
    for(let grandfather of getGrandfathers(allLocations)){
        locations.push({location_name:grandfather[0],location_id:grandfather[1].location_id})
        for(let father of getFathers(grandfather)){
            locations.push({location_name:father[0],location_id:father[1].id})
            for(let children of getChildrens(father)){
                locations.push({location_name:children[0],location_id:children[1].id})
            }
        }
    }
    return locations
}