import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { connect } from "react-redux"

import Header from "./header"
import Footer from "./footer"
import { useEffect } from "react"

import {
  CHANGE_APIKEY_ACTION,
  CHANGE_MAPKEY_ACTION,
} from "../redux/settingsDucks"
import { Toaster } from "react-hot-toast"
import Modules from "./modules"
import { useLocation } from "@reach/router"

const Layout = ({ dispatch, children }) => {
  const data = useStaticQuery(graphql`
    query LayoutInfoQuery {
      realEstate {
        seo {
          title
        }
        logo
        colors {
          primaryColor
          secondaryColor
          tertiaryColor
        }
        keys {
          tokko
          google_map
        }
      }
    }
  `)

  useEffect(() => {
  },[])

  useEffect(() => {
    dispatch(CHANGE_APIKEY_ACTION(data?.realEstate?.keys?.tokko))
    dispatch(CHANGE_MAPKEY_ACTION(data?.realEstate?.keys?.google_map))
    
  }, [data.realEstate])

  const location = useLocation()
  const pathname = location.pathname.replaceAll("/", "")

  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="PrmBUmnDG5hXRY_UJj2kvxTFikvtKi5XU-tbmtH_dPg" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
        <style>
          {`\
          :root{\
            --primaryColor: ${data.realEstate.colors.primaryColor};\
            --secondaryColor: ${data.realEstate.colors.secondaryColor};\
            --tertiaryColor: ${data.realEstate.colors.tertiaryColor};\
          }\
        `}
        </style>
      </Helmet>
      <article>
        <Toaster
          toastOptions={{
            className: "toast-className",
            style: {
              maxWidth: "fit-content",
            },
          }}
        />
        <Modules />
        <Header
          siteTitle={data.realEstate.seo?.title || `Title`}
          logo={data.realEstate.logo}
        />
        <main className={"main-principal " + (pathname === "" ? "isHome" : "")}>
          {children}
        </main>
        <Footer />
      </article>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({}), null)(Layout)
