import React, {useEffect} from "react";
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import toast from 'react-hot-toast';
import {existOperation,existDevelopment, getMessageWhatsapp, getPhonesText, makeItemsNav} from '../helpers/helper.rendering'
import { useLocation } from "@reach/router";

import {newSletterTokko,updateFields} from '../redux/contactDucks'

import { Helmet } from "react-helmet"
import DicLogoSvg from "../images/svg/dic-logo.svg"
import Modal from "./Modal";
import { CHANGE_HIDDEN_ACTION,GET_INVESTOR_ACTION,GET_CONFIGURATIONS_ACTION } from "../redux/settingsDucks";

const Footer = ({filters,api_key,developments,development,hiddenWhatsapp,property,dispatch,modules}) => {


  const [open,setOpen] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  const [hidden,setHidden] = useState(hiddenWhatsapp)

  const [flag,setFlag] = useState(false)

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        logo
        global_email
        fiscal_data
        bot_cliengo
        global_whatsapp
        global_phone
        global_broker_id
        keys{
            captcha
        }
        social{
          facebook
          instagram
          linkedin
          youtube
          twitter
        }
        branch_office{
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          footer {
              title_contact
              title_social
              title_newsletter
              title_office
              button_contact {
                  link
                  value
              }
          }
        }
      }
  }`) 
  const [disabled,setDisabled] = useState(true)
  const [datos, setDatos] = useState({
    email: "",
  });
  const handleInputChange = (e) => {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value,
      });
  };
  const sendForm = (e) => {
    e.preventDefault();
    dispatch(newSletterTokko())
    document.getElementById("formNewsletter").reset();
    return toast.success("Te suscribiste a nuestro newsletter correctamente.") 
  }
  // Función que devuelve el año actual
  const getCurrentYear = () => {
    const date = new Date().getFullYear()
    return date;
  }
    
  useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();
    if(!realEstate?.keys?.captcha){
      setDisabled(false)
    }
    else{
        setDisabled(true)
    }
  }, [datos]);

  const callCliengo = () => {
    if(!flag){
      var ldk = document.createElement('script');
      ldk.type = 'text/javascript';
      ldk.async = true;
      ldk.src = 'https://s.cliengo.com/weboptimizer/58135be4e4b0984a637cd2b0/'+ realEstate.bot_cliengo +'.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ldk, s);

      // var ldk = document.createElement('script');
      // ldk.type = 'text/javascript';
      // ldk.async = true;
      // ldk.src = '6LctTQUhAAAAABSDcQ6neEIomS23T2n-dvb3RRjo';
      // var s = document.getElementsByTagName('script')[0];
      // s.parentNode.insertBefore(ldk, s);
      setFlag(true)
    }
  }

    const hideOnDevs = location.pathname.includes("/emprendimientos/") && location.pathname.split("/").length === 3;
    const hideOnPorps = location.pathname.includes("/propiedad/") && location.pathname.split("/").length === 4;

    const checkToHide = () => {
      return pathname === "contacto" ||
        pathname === "nosotros" ||
        pathname === "tasaciones" ||
        pathname === "novedades" ||
        hideOnDevs ||
        hideOnPorps
        ? "d-none" : ""
    }

    const checkDevProp = () => {
      if (location.pathname.includes("/emprendimientos/")) {
        if (location.pathname.length > 17) {
          return true
        }
        return false
      } else if (location.pathname.includes("/propiedad/")
         || location.pathname.includes("/propiedad/venta")
         || location.pathname.includes("/propiedad/alquiler")
         || location.pathname.includes("/venta/")
         || location.pathname.includes("/alquiler/")) {
          return true 
      } else {
        return false
      }
    }

    const setStorage = (value) => {
      // localStorage.setItem("hidden-whatsapp", value);
      if(value === 'true'){
        dispatch(CHANGE_HIDDEN_ACTION(true))
      }
    }

    const checkStorage = () => {
      // if(typeof window !== "undefined"){
        if( hidden === true){
          return true
        }
        else{
          return false
        }
      // }
    }

    useEffect(() => {
      setHidden(hiddenWhatsapp)
    },[hiddenWhatsapp])

    // console.log(hiddenWhatsapp)

    useEffect(() => {
      dispatch(GET_INVESTOR_ACTION())
      dispatch(GET_CONFIGURATIONS_ACTION())
    },[])

    const INVESTOR_CONFIGURATION = modules.find(config => config.module === "INVESTOR")
    const OWNER_CONFIGURATION = modules.find(config =>config.module === "OWNER")
    const configUrl = (url) => (url?.includes("http")) ? url : `https:${url}`;

    return realEstate.sections && typeof window !== "undefined"? (
      <>
        {isBrowser && 
          <Helmet>
            {
              // realEstate.bot_cliengo ? 
                callCliengo()
                // :
                  // ''
              }
          </Helmet>
        }
        <div className="sup-footer">
          <div className="logo-wrapper py-5 d-none d-lg-flex justify-content-center align-items-center">
            <DicLogoSvg />
          </div>
          <div className={`mobile-separator d-flex d-lg-none ${checkToHide()}`}></div>
          <div className="social-wrapper d-flex justify-content-center align-items-center py-5">
            <a
              target="_blank"
              href={realEstate.social.instagram}
              class={realEstate.social.instagram ? "d-inline" : "d-none"}
            >
              <i class="icon-instagram"></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.facebook}
              class={realEstate.social.facebook ? "d-inline" : "d-none"}
            >
              <i class="icon-facebook"></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.linkedin}
              class={realEstate.social.linkedin ? "d-inline" : "d-none"}
            >
              <i class="icon-linkedin"></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.twitter}
              class={realEstate.social.twitter ? "d-inline" : "d-none"}
            >
              <i class="icon-twitter"></i>
            </a>
            <a
              target="_blank"
              href={realEstate.social.youtube}
              class={realEstate.social.youtube ? "d-inline" : "d-none"}
            >
              <i class="icon-youtube"></i>
            </a>
          </div>
          <div className="sucursales d-flex flex-column justify-content-center align-items-center py-lg-5">
            <h5 className="pb-5 pt-4 pt-lg-0">Sucursales</h5>
            <div className="container-fluid">
              <div className="grid">
                {realEstate.branch_office.length > 0 &&
                  realEstate.branch_office.map((branch, index) => (
                    <div key={index} className={"item-contact text-center " + (index === 0 ? 'd-block' : '') + (index === 6 ? " hide-last" : "")}>
                      <h5>{branch.name}</h5>
                      <div>
                        <p>{branch.address}</p>
                        {/* <p>{branch.city}</p> */}
                        {/* <p>{branch.region}</p> */}
                        {/* {branch.contact.phone} */}
                        {/* {branch.contact.phone.map(phone => (
                          <p>Teléfono {phone}</p>
                        ))} */}
                        <p className={branch.contact.phone === "" && "d-none"}>
                          {/*phone*/} {branch.contact.phone}
                        </p>
                        <p className={branch.broker_id === "" && "d-none"}>
                          Matrícula {branch.broker_id}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="conditional-grid d-flex justify-content-center d-sm-none">
                  <div className={"item-contact d-sm-none"}>
                    <h5>{realEstate.branch_office[6].name}</h5>
                    <div>
                      <p>{realEstate.branch_office[6].address}</p>
                      <p className={realEstate.branch_office[6].contact.phone === "" && "d-none"}>
                        {/*phone*/} {realEstate.branch_office[6].contact.phone}
                      </p>
                      <p className={realEstate.branch_office[6].broker_id === "" && "d-none"}>
                        Matrícula {realEstate.branch_office[6].broker_id}
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="contact-wrapper">
            <div className={`container-fluid`}>
              <div
                class={
                  "fixed-bar position-fixed" +
                  (realEstate.global_whatsapp === "" || checkStorage() ? " d-none" : "") +
                  (pathname === "emprendimientos" || pathname === "propiedad" ? " over-form" : "") +
                  ( checkDevProp() ? " d-none" : "" )
                }
              >
                <button style={{zIndex:100}} onClick={() =>  dispatch(CHANGE_HIDDEN_ACTION(true))} className="hiddenWhatsapp">
                  X
                </button>
                <a
                  href={
                    "https://web.whatsapp.com/send?text=" +
                    getMessageWhatsapp(development, property, location?.href) +
                    "&phone=+54" +
                    realEstate.global_whatsapp.replaceAll("-", "")
                  }
                  target="_blank"
                  class={"icon-whatsapp contact-btn d-none d-lg-flex "}
                ></a>
                <a
                  href={
                    "https://api.whatsapp.com/send?text=" +
                    getMessageWhatsapp(development, property, location?.href) +
                    "&phone=+54" +
                    realEstate.global_whatsapp.replaceAll("-", "")
                  }
                  target="_blank"
                  class={"icon-whatsapp contact-btn d-flex d-lg-none "}
                ></a>
              </div>
              <div className="row">
                <div className="col-lg-12 col-10 mx-auto offset-lg-0">
                  <div className="row">
                    <div className="col-lg-3 mx-auto col-md-12 order-1">
                      <div className="content-contact mt-auto">
                        <h4>{realEstate.sections?.footer?.title_newsletter}</h4>
                        <form onSubmit={sendForm} id="formNewsletter">
                          <div className="d-flex form-newsletter mb-lg-3">
                            <input
                              type="email"
                              onChange={handleInputChange}
                              name="email"
                              required
                              placeholder="Ingresá tu mail"
                              className="mr-2"
                            />
                            <button
                              disabled={disabled}
                              type="submit"
                              className="submit icon-email"
                            ></button>
                          </div>
                          {realEstate?.keys?.captcha ? (
                            <ReCAPTCHA
                              sitekey={
                                realEstate?.keys?.captcha
                                  ? realEstate?.keys?.captcha
                                  : 0
                              }
                              onChange={() => setDisabled(false)}
                            />
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </div>
                    <div className={"col-lg-3 mx-auto col-md-12 order-2 mt-5 mt-lg-0 " + ((INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module) ? "d-block" : "d-none" )}>
                      {/* <div className="item-contact row text-center">
                        <h5 className="col-12 title">DIC PRIVÉ</h5>
                        <div className="col-12">
                          <a
                            target="_blank"
                            className="btn btn-red d-inline-flex align-items-center"
                            href="https://mediacore.com.ar/app/"
                          >
                            Ingresar a la plataforma
                            <i class="icon-investor-access-light"></i>
                          </a>
                        </div>
                      </div> */}
                      {(INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module) ?
                        <div className="module-config-wrapper row text-center">
                          <h5 className="col-12 title">DIC PRIVÉ</h5>
                          <div className="col-12 d-flex flex-column">
                          { INVESTOR_CONFIGURATION?.module 
                            ? <a className="btn btn-red d-inline-flex align-items-center justify-content-center" href={configUrl(INVESTOR_CONFIGURATION.url_login)} target="__blank">
                                <i className="icon-investor-access-light me-2"></i>
                                <span className="text-capitalize">{INVESTOR_CONFIGURATION.text_cta || "Inversores"}</span>
                              </a> : null }
                          { OWNER_CONFIGURATION?.module 
                            ? <a className="btn btn-red d-inline-flex align-items-center justify-content-center mt-2" href={configUrl(OWNER_CONFIGURATION.url_login)} target="__blank">
                                <i className="icon-prop-type-light me-2"></i>
                                <span className="text-capitalize">{OWNER_CONFIGURATION.text_cta || "Propietarios"}</span>
                              </a> : null }
                          </div>
                          </div> : null }
                    </div>
                    <div className="col-lg-3 col-md-12 order-3 mt-5 mt-lg-0 mx-auto">
                      <div className="item-contact row text-center">
                        <h5 className="col-12 title">TRABAJÁ CON NOSOTROS</h5>
                        <div className="col-12">
                          <Link
                            className="btn btn-red d-inline-flex align-items-center justify-content-center"
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                            to={
                              realEstate?.sections?.footer?.button_contact?.link
                            }
                          >
                            Enviar mi cv
                            <i class="icon-expenses-light"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={`${hideOnDevs || hideOnPorps ? 'mb-bottom-footer' : 'footer-no-margin'}`}>
          <div className="container-fluid">
            <div className="row mt-lg-3 py-lg-3 d-flex justify-content-between align-items-center">
              <div className="col-md-8 col-7 offset-md-2 offset-1 d-flex align-items-center justify-content-center">
                {/* <p className="copy">
                  Designed by{" "}
                  <a href="https://mediahaus.com.ar">
                    {" "}
                    Mediahaus
                    <span
                      style={{ fontSize: "0.5rem", verticalAlign: "super" }}
                    >
                      ®{" "}
                    </span>{" "}
                  </a>{" "}
                  & Powered by{" "}
                  <a href="https://mediacore.com.ar/app">
                    {" "}
                    MediaCore
                    <span
                      style={{ fontSize: "0.5rem", verticalAlign: "super" }}
                    >
                      ®{" "}
                    </span>{" "}
                  </a>
                  {getCurrentYear()}. Todos los derechos&nbsp;reservados.
                </p> */}
                <p className="copy text-center">
                  <span class="media-text" style={{ fontSize: "0.9rem" }}>
                      Design by 
                      <a class="mediahaus-link" target="_blank" href="https://mediahaus.com.ar/">
                          MediaHaus
                      </a>
                       - Powered by <a class="mediacore-link" target="_blank" href="http://mediacore.app">
                      MediaCore</a>
                  </span>
                  <div style={{ height: 0,marginTop: "-.4rem" }}></div>
                  <span class="text-small" style={{ fontSize: "0.7rem" }}>Todos los derechos reservados <span
                      style={{ fontSize: "0.6rem", verticalAlign: "super" }}  
                    >®</span> {getCurrentYear()}</span>
                </p>
              </div>
              <div className="col-md-2 col-4">
                {realEstate?.fiscal_data ? (
                  <a
                    className="qr d-flex justify-content-end"
                    href={realEstate?.fiscal_data}
                    target="_F960AFIPInfo"
                  >
                    <img
                      src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                      border="0"
                    />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </footer>
        <Modal/>
      </>
    ) : (
      ""
    )
}

export default connect(state => ({
  filters:state.properties.totalListFilters,
  api_key:state.settings.keys.tokko,
  hiddenWhatsapp:state.settings.hiddenWhatsapp,
  developments:state.developments.developments,
  property:state.properties.property,
  development:state.developments.development,
  modules: state.settings.modules,
}),null)(Footer);