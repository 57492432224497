import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { connect } from "react-redux";
import { updateFields, formTasaciones_Tokko } from "../redux/contactDucks"

import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

const SendCvForm = ({dispatch}) => {

    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                keys {
                    captcha
                }
            }
    }`)

    const [disabled, setDisabled] = useState(true);
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        cv:''
    })

    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }


    const sendForm = (event) => {
        event.preventDefault()
        // dispatch(formTasaciones_Tokko(type.current.value,operation.current.value))
        window.scrollTo(0, 0)
        document.getElementById("formCvContact").reset()
        return toast.success("¡Mensaje enviado correctamente!") 
    }

    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])

    useEffect(() => {
        if(!realEstate?.keys?.captcha) {
            setDisabled(false)
          }
        else{
            setDisabled(true)
        }
    },[])

    return (
        <form onSubmit={sendForm} id="formCvContact" class="row content-form ajax-form">
            <div className="container-fluid">
                <div className="row">
                    <h1 className="text-center mb-5">¿Querés ser parte <br />de nuestro equipo?</h1>
                    <div class="col-12 mb-3">
                        <input onChange={handleInputChange} type="text" required name="nombre" placeholder="Nombre completo *" />
                    </div>
                    <div class="col-12 mb-3">
                        <input onChange={handleInputChange} type="text" required name="email" placeholder="Email *" />
                    </div>
                    <div className="col-12 mb-5">
                        <input class="form-control" type="file" name="cv" id="cv" />
                        {/* 
                            Para estilar el input file probar con esta guia:
                            https://www.codemzy.com/blog/react-drag-drop-file-upload
                        */}
                    </div>
                    <div class="col-12 mb-5">
                        <div className="d-flex justify-content-center">
                            { realEstate?.keys?.captcha ? 
                                <ReCAPTCHA
                                    sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                                    onChange ={() => setDisabled(false)} /> : '' }
                        </div>
                        <button disabled={disabled} type="submit" id="submit-info"
                            class="btn btn-red d-flex submit justify-content-center align-items-center mt-4 mx-auto">
                            ENVIAR
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default connect(state => ({}), null)(SendCvForm);